// Adiciona o evento DOMContentLoaded para garantir que o DOM esteja totalmente carregado antes de manipular elementos.
document.addEventListener('DOMContentLoaded', function () {
    
    // Verifique se o botão 'calcularckd' existe antes de adicionar o event listener
    const calcularCkdButton = document.getElementById('calcularckd');
    if (calcularCkdButton) {
        calcularCkdButton.addEventListener('click', calcularckdclidado);
    }

    // Verifique se o botão 'reseta1' existe antes de adicionar o event listener
    const reseta1Button = document.getElementById('reseta1');
    if (reseta1Button) {
        reseta1Button.addEventListener('click', limparclicado1);
    }

    // Verifique se o botão 'calcularcft' existe antes de adicionar o event listener
    const calcularCftButton = document.getElementById('calcularcft');
    if (calcularCftButton) {
        calcularCftButton.addEventListener('click', calcularcftclicado);
    }

    // Verifique se o botão 'reseta2' existe antes de adicionar o event listener
    const reseta2Button = document.getElementById('reseta2');
    if (reseta2Button) {
        reseta2Button.addEventListener('click', limparclicado2);
    }

});

// Função que calcula o clearance pela fórmula CKD-EPI
export function calcularckdclidado() {
    document.querySelector('#output').classList.remove('vermelho');
    document.querySelector('#output').classList.remove('amarelo');
    document.querySelector('#output').classList.add('azul');
    
    let cr = parseFloat(document.querySelector('#cr').value);
    let idade = parseFloat(document.querySelector('#idade').value);
    
    if (isNaN(cr) || isNaN(idade)) {
        return document.querySelector("#output").innerHTML = "Preencha todos os dados acima";
    }
    
    let sexo = document.querySelector('input[name="sexo"]:checked').value;
    let clcr = '';
    let resultado = '';
    let alb = "";
    let ral = parseFloat(document.querySelector("#a1").value);

    // Avaliação da albuminúria
    if (ral < 30) alb = " A1";
    if (ral >= 30 && ral <= 300) alb = " A2";
    if (ral > 300) alb = " A3";
    
    // Cálculo da fórmula CKD-EPI
    if (sexo === 'h' && cr <= 0.9) clcr = 142 * Math.pow((cr / 0.9), -0.302) * Math.pow(0.9938, idade);
    if (sexo === 'm' && cr <= 0.7) clcr = 142 * Math.pow((cr / 0.7), -0.241) * Math.pow(0.9938, idade) * 1.012;
    if (sexo === 'h' && cr > 0.9) clcr = 142 * Math.pow((cr / 0.9), -1.200) * Math.pow(0.9938, idade);
    if (sexo === 'm' && cr > 0.7) clcr = 142 * Math.pow((cr / 0.7), -1.200) * Math.pow(0.9938, idade) * 1.012;

    clcr = clcr.toFixed(2);

    // Definição do estágio da doença renal
    if (clcr >= 90) resultado = 'Estágio G1' + alb + ' da doença renal, função renal preservada.';
    if (clcr < 90 && clcr >= 60) resultado = 'Estágio G2' + alb + ' da doença renal.';
    if (clcr < 60 && clcr >= 45) resultado = 'Estágio G3a' + alb + ' da doença renal, recomendada avaliação de um Nefrologista.';
    if (clcr < 45 && clcr >= 30) resultado = 'Estágio G3b' + alb + ' da doença renal, recomendado acompanhamento com Nefrologista.';
    if (clcr < 30 && clcr >= 15) resultado = 'Estágio G4' + alb + ' da doença renal, recomendado acompanhamento com Nefrologista.';
    if (clcr < 15) resultado = 'Estágio G5' + alb + ' da doença renal, níveis alarmantes, procure um Nefrologista com urgência!';

    document.querySelector('#output').innerHTML = 'O ClCr estimado é ' + clcr + 'ml/min/1,73m2. ' + "<p>" + resultado;
    addclasse(clcr);
    document.getElementById('cr').focus();
}

// Função de limpar os campos para a fórmula CKD-EPI
export function limparclicado1() {
    document.querySelector('#output').innerHTML = '';
    document.querySelector('#cr').value = "";
    document.querySelector('#idade').value = "";
    document.querySelector('#a1').value = "";
}

// Função de adicionar cores ao resultado de acordo com o ClCr
export function addclasse(x) {
    const resultadocor = document.querySelector('#output');
    if (x < 60 && x > 15) {
        resultadocor.classList.add('amarelo');
        resultadocor.classList.remove('azul');
    }
    if (x <= 15) {
        resultadocor.classList.add('vermelho');
        resultadocor.classList.remove('azul');
    }
}

// Função que calcula o clearance pela fórmula Cockcroft-Gault
function calcularcftclicado() {
    document.querySelector('#output').classList.remove('vermelho');
    document.querySelector('#output').classList.remove('amarelo');
    document.querySelector('#output').classList.add('azul');
    
    let cr = parseFloat(document.querySelector("#crg").value);
    let idade = parseFloat(document.querySelector("#idadeg").value);
    let peso = parseFloat(document.querySelector("#pesog").value);
    
    if (isNaN(cr) || isNaN(idade) || isNaN(peso)) {
        return document.querySelector("#output").innerHTML = "Preencha todos os dados acima";
    }
    
    let sexo = document.querySelector('input[name="sexog"]:checked').value;
    let clcr = (140 - idade) * peso / (cr * 72);
    clcr = clcr.toFixed(2);

    let cf;
    switch (sexo) {
        case "hg":
            cf = clcr;
            break;
        case "mg":
            cf = ((clcr * 85) / 100).toFixed(2);
            break;
    }

    let resultado = "";
    if (cf >= 60) {
        resultado = "ClCr estimado: " + cf + " ml/min/1,73m2";
    } else {
        resultado = "ClCr estimado: " + cf + " ml/min/1,73m2. Recomendada a avaliação de um nefrologista!";
    }

    addclasse(cf);
    document.querySelector("#output").innerHTML = resultado;
    document.getElementById('crg').focus();
}

// Função de limpar os campos para a fórmula Cockcroft-Gault
export function limparclicado2() {
    document.querySelector("#crg").value = "";
    document.querySelector("#idadeg").value = "";
    document.querySelector("#pesog").value = "";
    document.querySelector("#output").innerHTML = "";
}
