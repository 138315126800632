import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Login from './modules/login';

//PARA INSTALAR O BUSCADOR NO SELECT
import 'select2/dist/css/select2.min.css'; // Importando o CSS do Select2
import $ from 'jquery'; // Importando jQuery
import 'select2'; // Importando o plugin Select2

$(document).ready(function() {
  const temaSelect = $('#fqual');

  // Inicializa o select2 com placeholder e a opção de limpar
  temaSelect.select2({
      placeholder: 'Selecione um tema',
      allowClear: true
  });

  // Evento disparado quando uma opção é selecionada no select2
  temaSelect.on('select2:select', function(e) {
      const selectedOption = e.params.data.id;
      console.log("Select2 opção selecionada:", selectedOption); // Debugging
      let resultadodiversos=document.querySelector("#resultadoformula");
      resultadodiversos.innerHTML = "";
      //console.log('oi');
      // Mapeamento das divs que serão exibidas para cada opção selecionada
      const divMap = {
          'nada': '#nada',
          'ac': '#ac',
          'fena': '#fena',
          'heparina': '#heparina',
          'hidradiaria': '#hidradiaria',
          'hidradengue': '#hidradengue',
          'hipercalemia': '#hipercalemia',
          'hipocalemia': '#hipocalemia',
          'hiponatremia': '#hiponatremia',
          'osmolaridade': '#osmolaridade',
          'imc': '#imc',
          'bic': '#bic',
          'pfr': '#pfr',
          'ag': '#ag',
          'ist': '#ist',
          'glasgow': '#glasgow',
          'mckg': '#mckg',
          'clcrped': '#clcrped',
          'ingestasal': '#ingestasal',
          'kfre': '#kfre',
          'cvvhdf': '#cvvhdf',
          'dab': '#dab',
          'cacor': '#cacor',
          'am': '#am'
      };

      // Esconde todas as divs de conteúdo
      $('.conteudo').hide();  // Esconde todas as divs com a classe .conteudo

      // Mostra a div correspondente à opção selecionada, se existir
      if (divMap[selectedOption]) {
          $(divMap[selectedOption]).show();
      }
  });
});


const cadastro = new Login('.form-cadastro');
const login = new Login('.form-login');
cadastro.init();
login.init();


import Contato from './modules/contato';

const contato = new Contato('.form-contato');
contato.init();

import './assets/css/style.css';

import { limparclicado1, limparclicado2, calcularckdclidado, calcularcftclicado, addclasse } from './modules/clerance';

import { ajustemedicamentos, resetarclicado, infoclidado, linkclicado } from './modules/ajustemedicamentos';

import { calcularimcclicado, calcularacclidado, calculariagclicado, calcularcaaclicado, calcularclcrpedclicado, referenciaclicado, calcularmckgclicado,
         calcularhephepclicado, calcularhepenoxclicado, calcularfenaclicado, calcularpfrclicado, calcularglasgowclicado, calcularhidraclicado, calcularhidradclicado,
         calcularistclicado, calcularosmclicado, calcularrepobicclicado, infofluxodclicado, infofluxotclicado,
         resetardiversosacclicado, resetardiversosagclicado, resetardiversoscaaclicado, resetardiversosclcrpedclicado, resetardiversosmckgclicado,
         resetardiversoshepclicado, resetardiversosfenaclicado, resetardiversosimcclicado, resetardiversospfrclicado, resetardiversoshidraclicado, 
         resetardiversoshidradclicado, resetardiversosistclicado, resetardiversososmclicado, resetardiversosrepobicclicado } from './modules/diversos';



   

  
