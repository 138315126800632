document.addEventListener('contextmenu', function(e) {
    if (e.target.tagName === 'IMG') {
        e.preventDefault();
    }
});

document.addEventListener("DOMContentLoaded", function() {

     // Adicionar os event listeners assim que a página carregar
     const addEventListeners = () => {

        // Verifique se o botão 'calcularac' existe antes de adicionar o event listener
        const calcularacButton = document.getElementById('calcularac');
        if (calcularacButton) {
            calcularacButton.addEventListener('click', calcularacclidado);
        }

        // Verifique se o botão 'calcularag' existe antes de adicionar o event listener
        const calcularagButton = document.getElementById('calcularag');
        if (calcularagButton) {
            calcularagButton.addEventListener('click', calcularagclicado);
        }

        // Verifique se o botão 'calcularcaajuste' existe antes de adicionar o event listener
        const calcularcaaButton = document.getElementById('calcularcaa');
        if (calcularcaaButton) {
            calcularcaaButton.addEventListener('click', calcularcaaclicado);
        }

        // Verifique se o botão 'calcularclcrped' existe antes de adicionar o event listener
        const calcularclcrpedButton = document.getElementById('calcularclcrped');
        if (calcularclcrpedButton) {
            calcularclcrpedButton.addEventListener('click', calcularclcrpedclicado);
        }

        // Verifique se o botão 'referencia' existe antes de adicionar o event listener
        const referenciaButton = document.getElementById('referencia');
        if (referenciaButton) {
            referenciaButton.addEventListener('click', referenciaclicado);
        }

        // Verifique se o botão 'calcularmckg' existe antes de adicionar o event listener
        const calcularmckgButton = document.getElementById('calcularmckg');
        if (calcularmckgButton) {
            calcularmckgButton.addEventListener('click', calcularmckgclicado);
        }

        // Verifique se o botão 'calcularhephep' existe antes de adicionar o event listener
        const calcularhephepButton = document.getElementById('calcularhephep');
        if (calcularhephepButton) {
            calcularhephepButton.addEventListener('click', calcularhephepclicado);
        }

        // Verifique se o botão 'calcularhepenox' existe antes de adicionar o event listener
        const calcularhepenoxButton = document.getElementById('calcularhepenox');
        if (calcularhepenoxButton) {
            calcularhepenoxButton.addEventListener('click', calcularhepenoxclicado);
        }

        // Verifique se o botão 'calcularfena' existe antes de adicionar o event listener
        const calcularfenaButton = document.getElementById('calcularfena');
        if (calcularfenaButton) {
            calcularfenaButton.addEventListener('click', calcularfenaclicado);
        }

        // Verifique se o botão 'calcularpfr' existe antes de adicionar o event listener
        const calcularpfrButton = document.getElementById('calcularpfr');
        if (calcularpfrButton) {
            calcularpfrButton.addEventListener('click', calcularpfrclicado);
        }

        // Verifique se o botão 'calcularglasgow' existe antes de adicionar o event listener
        const calcularglasgowButton = document.getElementById('calcularglasgow');
        if (calcularglasgowButton) {
            calcularglasgowButton.addEventListener('click', calcularglasgowclicado);
        }

        // Verifique se o botão 'calcularhidra' existe antes de adicionar o event listener
        const calcularhidraButton = document.getElementById('calcularhidra');
        if (calcularhidraButton) {
            calcularhidraButton.addEventListener('click', calcularhidraclicado);
        }

        // Verifique se o botão 'calcularhidrad' existe antes de adicionar o event listener
        const calcularhidradButton = document.getElementById('calcularhidrad');
        if (calcularhidradButton) {
            calcularhidradButton.addEventListener('click', calcularhidradclicado);
        }

         // Verifique se o botão 'calcularimc' existe antes de adicionar o event listener
         const calcularimcButton = document.getElementById('calcularimc');
         if (calcularimcButton) {
             calcularimcButton.addEventListener('click', calcularimcclicado);
         }

         // Verifique se o botão 'calcularist' existe antes de adicionar o event listener
         const calcularistButton = document.getElementById('calcularist');
         if (calcularistButton) {
             calcularistButton.addEventListener('click', calcularistclicado);
         }

         // Verifique se o botão 'calcularsal' exsale antes de adicionar o event listener
         const calcularsalButton = document.getElementById('calcularsal');
         if (calcularsalButton) {
             calcularsalButton.addEventListener('click', calcularsalclicado);
         }

         // Verifique se o botão 'calcularosm' exosme antes de adicionar o event listener
         const calcularosmButton = document.getElementById('calcularosm');
         if (calcularosmButton) {
             calcularosmButton.addEventListener('click', calcularosmclicado);
         }

          // Verifique se o botão 'calcularrepobic' exrepobice antes de adicionar o event listener
          const calcularrepobicButton = document.getElementById('calcularrepobic');
          if (calcularrepobicButton) {
              calcularrepobicButton.addEventListener('click', calcularrepobicclicado);
          }

           // Verifique se o botão 'hiponatremia' exrepobice antes de adicionar o event listener
           const infofluxodButton = document.getElementById('infofluxod');
           if (infofluxodButton) {
               infofluxodButton.addEventListener('click', infofluxodclicado);
           }

           // Verifique se o botão 'hiponatremia' exrepobice antes de adicionar o event listener
           const infofluxotButton = document.getElementById('infofluxot');
           if (infofluxotButton) {
               infofluxotButton.addEventListener('click', infofluxotclicado);
           }
         


        // Verifique se o botão 'resetardiversos' existe antes de adicionar o event listener
        const resetardiversosacButton = document.getElementById('resetardiversosac');
        if (resetardiversosacButton) {
            resetardiversosacButton.addEventListener('click', resetardiversosacclicado);
        }

        const resetardiversosimcButton = document.getElementById('resetardiversosimc');
        if (resetardiversosimcButton) {
            resetardiversosimcButton.addEventListener('click', resetardiversosimcclicado);
        }

        const resetardiversosagButton = document.getElementById('resetardiversosag');
        if (resetardiversosagButton) {
            resetardiversosagButton.addEventListener('click', resetardiversosagclicado);
        }

        const resetardiversoscaaButton = document.getElementById('resetardiversoscaa');
        if (resetardiversoscaaButton) {
            resetardiversoscaaButton.addEventListener('click', resetardiversoscaaclicado);
        }

        const resetardiversosclcrpedButton = document.getElementById('resetardiversosclcrped');
        if (resetardiversosclcrpedButton) {
            resetardiversosclcrpedButton.addEventListener('click', resetardiversosclcrpedclicado);
        }

        const resetardiversosmckgButton = document.getElementById('resetardiversosmckg');
        if (resetardiversosmckgButton) {
            resetardiversosmckgButton.addEventListener('click', resetardiversosmckgclicado);
        }

        const resetardiversoshepButton = document.getElementById('resetardiversoshep');
        if (resetardiversoshepButton) {
            resetardiversoshepButton.addEventListener('click', resetardiversoshepclicado);
        }

        const resetardiversosfenaButton = document.getElementById('resetardiversosfena');
        if (resetardiversosfenaButton) {
            resetardiversosfenaButton.addEventListener('click', resetardiversosfenaclicado);
        }

        const resetardiversospfrButton = document.getElementById('resetardiversospfr');
        if (resetardiversospfrButton) {
            resetardiversospfrButton.addEventListener('click', resetardiversospfrclicado);
        }

        const resetardiversoshidraButton = document.getElementById('resetardiversoshidra');
        if (resetardiversoshidraButton) {
            resetardiversoshidraButton.addEventListener('click', resetardiversoshidraclicado);
        }

        const resetardiversoshidradButton = document.getElementById('resetardiversoshidrad');
        if (resetardiversoshidradButton) {
            resetardiversoshidradButton.addEventListener('click', resetardiversoshidradclicado);
        }

        const resetardiversosistButton = document.getElementById('resetardiversosist');
        if (resetardiversosistButton) {
            resetardiversosistButton.addEventListener('click', resetardiversosistclicado);
        }

        const resetardiversossalButton = document.getElementById('resetardiversossal');
        if (resetardiversossalButton) {
            resetardiversossalButton.addEventListener('click', resetardiversossalclicado);
        }

        const resetardiversososmButton = document.getElementById('resetardiversososm');
        if (resetardiversososmButton) {
            resetardiversososmButton.addEventListener('click', resetardiversososmclicado);
        }

        const resetardiversosrepobicButton = document.getElementById('resetardiversosrepobic');
        if (resetardiversosrepobicButton) {
            resetardiversosrepobicButton.addEventListener('click', resetardiversosrepobicclicado);
        }}

    addEventListeners();
  

    document.addEventListener('change', function() {
        const select = document.getElementById('fqual');
        const selectedOption = select.value;
        const divs = document.querySelectorAll('.formula1 > div');
        
        // Oculta todas as divs
        divs.forEach(div => div.style.display = 'none');
        document.querySelector("#resultadoformula").innerHTML = "";

        
        // Mapeia os valores do select para os IDs das divs
        const divMap = {
            'nada': '#nada',
            'ac': '#ac',
            'fena': '#fena',
            'heparina': '#heparina',
            'hidradiaria': '#hidradiaria',
            'hidradengue': '#hidradengue',
            'hipercalemia': '#hipercalemia',
            'hipocalemia': '#hipocalemia',
            'hiponatremia': '#hiponatremia',
            'osmolaridade': '#osmolaridade',
            'imc': '#imc',
            'bic': '#bic',
            'pfr': '#pfr',
            'ag': '#ag',
            'ist': '#ist',
            'glasgow': '#glasgow',
            'mckg': '#mckg',
            'clcrped': '#clcrped',
            'ingestasal': '#ingestasal',
            'kfre': '#kfre',
            'cvvhdf': '#cvvhdf',
            'dab': '#dab',
            'cacor': '#cacor',
            'am': '#am'
        };

        // Exibe a div correspondente à opção selecionada
        const divToShow = document.querySelector(divMap[selectedOption]);
        if (divToShow) {
            divToShow.style.display = 'block';
            
        }

        // Funções específicas para certas opções
        if (selectedOption === 'hiponatremia') {
            window.open('hiponatremia.html', '_target');
        } else if (selectedOption === 'glasgow') {
            divToShow.style.display = 'grid';
            calcularglasgowclicado();
        } else if (selectedOption === 'clcrped') {
            jasim = false;
        }
    });
});

let jasim=false;
export function resetardiversosacclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector("#pesoac").value='';
    jasim=false;
}

export function resetardiversosimcclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#pesoimc').value='';
    document.querySelector('#alturaimc').value='';
    jasim=false;
}

export function resetardiversosagclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector("#sodioag").value="";
    document.querySelector("#cloroag").value="";
    document.querySelector("#bicag").value="";
    jasim=false;
}

export function resetardiversoscaaclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#catotal').value='';
    document.querySelector('#albumina').value='';
    jasim=false;
}

export function resetardiversosclcrpedclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#alturaped').value='';
    document.querySelector('#crped').value='';
    jasim=false;
}

export function resetardiversosmckgclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#infusao').value='';
    document.querySelector('#pesoml').value='';
    document.querySelector('#soro').value='';
    document.querySelector('#dosagem').value='';
    jasim=false;
}

export function resetardiversoshepclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector("#pesohep").value='';
    jasim=false;
}

export function resetardiversosfenaclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#sodios').value='';
    document.querySelector('#sodiou').value='';
    document.querySelector('#crs').value='';
    document.querySelector('#cru').value='';
    jasim=false;
}

export function resetardiversospfrclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#idadepfr').value='';
    jasim=false;
}

export function resetardiversoshidraclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#pesoh').value='';
    jasim=false;
}

export function resetardiversoshidradclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#pesod').value='';
    jasim=false;
}

export function resetardiversosistclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#ferro').value='';
    document.querySelector('#tibc').value='';
    jasim=false;
}

export function resetardiversossalclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#sali').value='';
    jasim=false;
}

export function resetardiversososmclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#sodio').value='';
    document.querySelector('#glicose').value='';
    document.querySelector('#ureia').value='';
    jasim=false;
}

export function resetardiversosrepobicclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    document.querySelector('#pesorep').value='';
    document.querySelector('#base').value='';
    jasim=false;
}

//HIPONATREMIA

function mostrarImagem(url) {
    const diva = document.querySelector("#resultadoformula");
    diva.innerHTML = ""; // Limpa a div antes de adicionar nova imagem
    const clcrrn = document.createElement('img');
    clcrrn.src = url;
    clcrrn.classList.add('hiponatremia', 'mx-auto', 'img-fluid');
    diva.appendChild(clcrrn);
}

export function infofluxodclicado() {
    console.log('oi');
    mostrarImagem('/assets/image/hiponatremiad.png');
    jasim = true; // Aqui você pode manter essa lógica se for necessário
}

export function infofluxotclicado() {
    mostrarImagem('/assets/image/hiponatremiat.png');
    jasim = true; // Aqui você pode manter essa lógica se for necessário
}

//ÁGUA CORPORAL

export function calcularacclidado(){
    let idoso=document.querySelector("#idoso").checked;
    let ad='';
    let pesoac=document.querySelector("#pesoac").value
    pesoac=Number(pesoac)
    let sexoac= document.querySelector('input[name="sexoac"]:checked').value;
    if(!pesoac){ return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados acima"}
    if(idoso){
        switch(sexoac){
            case 'hgac': ad=pesoac*0.5
                break;
            case 'mgac': ad=pesoac*0.45
        }
    } else{
        switch(sexoac){
            case 'hgac': ad=pesoac*0.6
                break;
            case 'mgac': ad=pesoac*0.5
        }
    }
    document.querySelector('#resultadoformula').innerHTML= "<h3>" + 'Água corporal estimada: ' + ad + " litros"; 
}

//ANION GAP

export function calcularagclicado(){
    let sodioag= document.querySelector('#sodioag').value
    sodioag=Number(sodioag)
    let cloroag=document.querySelector('#cloroag').value
    cloroag=Number(cloroag)
    let bicag=document.querySelector('#bicag').value
    bicag=Number(bicag)
    //let kag=document.querySelector('#kag').value
    //kag=Number(kag)
    if(!sodioag || !cloroag || !bicag) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let ag= sodioag - (cloroag + bicag);
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+'Ânion gap calculado é: ' + ag +'mEq/l';
}

//AJUSTE DE CÁLCIO PARA ALBUMINA SÉRICA

export function calcularcaaclicado(){
    let catotal= document.querySelector('#catotal').value
    catotal=Number(catotal)
    let albumina=document.querySelector('#albumina').value
    albumina=Number(albumina)
    if(!catotal || !albumina) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let caajustado= catotal + 0.8*(4-albumina);
    if(albumina>4){return document.querySelector('#resultadoformula').innerHTML= "<h3>"+'Não é necessária correção, nível normal de albumina'+ "<br>";}
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+'Cálcio ajustado: '+caajustado+"mg/dl"+ "<br>";
}

//CÁLCULO DE CLCR EM PEDIATRIA

export function calcularclcrpedclicado(){
    jasim=false;
    let altura= document.querySelector('#alturaped').value;
    altura= Number(altura);
    let creatinina = document.querySelector('#crped').value;
    creatinina= Number(creatinina);
    let select= document.getElementById('idadep').value;
    let k='';
    switch (select) {
        case 'ptmbp': k=0.29;
            break;
        case 'ptbp': k=0.33;
            break;
        case 'rnt': k=0.45;
            break;
        case 'cri': k=0.55;
            break;
        case 'crim': k=0.7;
            break;
        default:
            break;
    }
    if(!altura || !creatinina || !k) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let clcrped= ((altura*k)/creatinina).toFixed(2);
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+ clcrped + 'ml/min/1,73m2';
}

export function referenciaclicado(){
    document.querySelector("#resultadoformula").innerHTML="";
    if(jasim){return jasim=false}
    const clcrrn=document.createElement('img');
    clcrrn.src= '/assets/image/clcrrn.png';
    const diva= document.querySelector('#resultadoformula');
    clcrrn.classList.add('clcrrn');
    clcrrn.classList.add('mx-auto');

    diva.appendChild(clcrrn);
    jasim=true;
}

//CONVERTER ML/H EM MCG/KG/MIN

export function calcularmckgclicado(){
    let infusao= document.querySelector('#infusao').value
    infusao=Number(infusao)
    let pesoml=document.querySelector('#pesoml').value
    pesoml=Number(pesoml)
    let soro=document.querySelector('#soro').value
    soro=Number(soro)
    let dosagem=document.querySelector('#dosagem').value
    dosagem=Number(dosagem)
    if(!infusao || !pesoml || !soro || !dosagem) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let mckg= (infusao*dosagem*1000)/(soro*pesoml*60);
    mckg= mckg.toFixed(2);
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+ mckg + 'mcg/kg/min';
}

//HEPARINA EM HD

export function calcularhephepclicado(){
    let peso= document.querySelector("#pesohep").value
    peso= Number(peso)
    if(!peso){return document.querySelector("#resultadoformula").innerHTML='preencha o peso'}
    var pesomin= peso/100
    var pesomax= (peso*3)/100
    pesomin= pesomin.toFixed(1)
    pesomax= pesomax.toFixed(1)
    
    document.querySelector("#resultadoformula").innerHTML= "A dose recomendada é de "+pesomin + " a " + pesomax+ 
    " ml de heparina (5000UI/ml)."
}

export function calcularhepenoxclicado(){
    let peso= document.querySelector("#pesohep").value
    peso= Number(peso) 
    if(!peso){ return document.querySelector("#resultadoformula").innerHTML='preencha o peso'}
    document.querySelector("#resultadoformula").innerHTML= "A dose recomendada é de "+peso + "mg."
}

//FRAÇÃO DE EXCREÇÃO DE SÓDIO  FeNa= (NaU X CrS) / (NaS X CrU)   x 100%

export function calcularfenaclicado(){
    let sodios= document.querySelector('#sodios').value
    sodios=Number(sodios)
    let sodiou=document.querySelector('#sodiou').value
    sodiou=Number(sodiou)
    let crs=document.querySelector('#crs').value
    crs=Number(crs)
    let cru=document.querySelector('#cru').value
    cru=Number(cru)
    if(!sodios || !sodiou || !crs || !cru) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let fena= (sodiou*crs)/(sodios*cru) *100;
    fena= fena.toFixed(2);
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+'A fração de excreção de sódio é: ' + fena +"%";
}

//FUNÇÃO RENAL ESPERADA PARA IDADE

export function calcularpfrclicado(){
    let idadepfr= document.querySelector('#idadepfr').value;
    idadepfr=Number(idadepfr);
    if(!idadepfr){return document.querySelector("#resultadoformula").innerHTML='preencha a idade'}
    let pfr='';
    let pfrmin="";
    let pfrmax="";
    if(idadepfr<=40){pfr= " >=90"} else {pfrmin= 90 - (idadepfr-40)*0.5; 
    pfrmax= 90 - (idadepfr-40); pfr=" de " + pfrmax +" a " + pfrmin}
    document.querySelector("#resultadoformula").innerHTML= "O ClCr esperado para a idade, aproximadamente é" + pfr + "ml/min/1,73m2"
}

//GLASGOW

export function calcularglasgowclicado(){
    let ao=document.querySelector('input[name="aocular"]:checked').value;
    ao=Number(ao);
    let rm=document.querySelector('input[name="rm"]:checked').value;
    rm=Number(rm);
    let rv=document.querySelector('input[name="rv"]:checked').value;
    rv=Number(rv);
    let glasgow= ao + rm + rv;
    if(ao&&rm&&rv){
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+ "Glasgow: " + glasgow;}
}

//HIDRATAÇÃO ORAL

export function calcularhidraclicado(){
    let pesoh=document.querySelector("#pesoh").value
    pesoh=Number(pesoh);
    if(!pesoh){ return document.querySelector("#resultadoformula").innerHTML="preencha o peso"}
    let hidra= pesoh*35;
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+'A hidratação oral média recomendada é '+ '<big>' +hidra+'</big>'+"ml em 24h";
}

//HIDRATAÇÃO DENGUE

export function calcularhidradclicado(){
    let pesod=document.querySelector("#pesod").value
    pesod=Number(pesod);
    if(!pesod){ return document.querySelector("#resultadoformula").innerHTML="preencha o peso"}
    let hidrad= pesod*60;
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+'A hidratação oral diária recomendada é de '+ '<big>' +hidrad/3+'</big>'+"ml de soro de reidratação oral (SRO) e " +
    '<big>' +hidrad*(2/3)+'</big>'+ 'ml dos demais líquidos.';
}

//ÍNDICE DE MASSA CORPORAL

export function calcularimcclicado(){
    let pesoimc= document.querySelector('#pesoimc').value
    pesoimc=Number(pesoimc)
    let alturaimc=document.querySelector('#alturaimc').value
    alturaimc=Number(alturaimc)
    if(!pesoimc || !alturaimc) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let resultadoimc= (pesoimc*10000)/(alturaimc**2)
    resultadoimc=parseFloat(resultadoimc.toFixed(2))
    let texto='';
    if(resultadoimc<18.5){texto="Você está ABAIXO DO PESO, melhora sua alimentação!"}else{
    if(resultadoimc>=18.5&&resultadoimc<=24.9){texto='Você está muito bem!'} else{
    if(resultadoimc>24.9&&resultadoimc<=29.9){texto='<u>'+ 'SOBREPESO'+'</u>'+ ': melhore seu estilo de vida!'}else{
    if(resultadoimc>29.9&&resultadoimc<=34.9){texto= 'você está com ' + '<u>'+ 'OBESIDADE GRAU 1'+'</u>'+ ': fique atento!'}else{
    if(resultadoimc>34.9&&resultadoimc<=39.9){texto= '<u>' +'OBESIDADE GRAU 2'+'</u>' + ': procure ajuda!'}
    else{texto='<u>'+'OBESIDADE GRAU 3'+'</u>'+ ': procure ajuda urgente!'}}}}}
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+'Seu IMC é: '+resultadoimc+"Kg/m²"+ "<br>" + texto;
}

// ÍNDICE DE SATURAÇÃO DE TRANSFERRINA

export function calcularistclicado(){
    let ferro= document.querySelector('#ferro').value;
    let tibc= document.querySelector('#tibc').value;
    let ist= (ferro/tibc)*100;
    if(!ferro || !tibc) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+"O índice de saturação de transferrina é: "
    + ist + '%.'
}

//INGESTÃO DE SÓDIO

export function calcularsalclicado(){
    let natriuria= document.querySelector("#sali").value;
    if(!natriuria) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let ingestasal= natriuria/17
    ingestasal= Number(ingestasal.toFixed(1));
    
    document.querySelector("#resultadoformula").innerHTML="O valor ingerido de NaCl é de aproximadamente " + ingestasal + " gramas ao dia."
    
}

//OSMOLARIDADE

export function calcularosmclicado(){
    let sodio= document.querySelector('#sodio').value
    sodio=Number(sodio)
    let glicose=document.querySelector('#glicose').value
    glicose=Number(glicose)
    let ureia=document.querySelector('#ureia').value
    ureia=Number(ureia)
    if(!sodio || !glicose || !ureia) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let resultado= 2*sodio + glicose/18 + ureia/6;
    resultado=parseFloat(resultado.toFixed(2))
    let texto='';
    if(resultado<280){texto="Osmolaridade baixa."} else
    if(resultado>=280&&resultado<=295){texto='Osmolaridade normal.'} else{
    texto='Osmolaridade aumentada.'}
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+'Osmolaridade sérica: '+resultado+"mOsm/litro"+ "<br>" + texto;
}

//REPOSIÇÃO DE BIC

export function calcularrepobicclicado(){
    let pesorep= document.querySelector('#pesorep').value
    pesorep=Number(pesorep)
    let base=document.querySelector('#base').value
    base=Number(base)
    if(!pesorep || !base) {return document.querySelector("#resultadoformula").innerHTML="preencha todos os dados"}
    let repobic= pesorep*0.3*base;
    document.querySelector('#resultadoformula').innerHTML= "<h3>"+"Reposição com Bicarbonato de Sódio 8,4%:"
    + "<br>" + (Math.abs(repobic)/3).toFixed(1) + "ml a cada 8h EV" + "<br>" + "ou" + "<br>" + "empiricamente "
    + pesorep + 'ml a cada 8h EV';
}






